import React from "react"
import styled from "@emotion/styled"

import LogoLockupSvg from "src/assets/images/Logo_TenderCare.svg"

import { animations } from "src/styles"

const LogoWrapper = styled.div`
  height: auto;
  display: inline-block;
  vertical-align: top;
  svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: auto;
    color: inherit;
    * {
      transition: fill ${animations.mediumSpeed} ease-in-out;
      fill: var(--text-color);
      &.heart {
        fill: url(#logo-gradient) var(--main-color);
      }
    }
  }
`

const LogoLockup = ({ className }) => (
  <LogoWrapper className={className}>
    <LogoLockupSvg />
  </LogoWrapper>
)

const Logo = LogoLockup

export default Logo
